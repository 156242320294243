<template lang="html">
  <div id="about-us-page">

    <section class="banner-section">

    </section>

    <section class="info-section">
      <div class="container oversized-container box-main">
        <div class="row">
          <div class="col-lg-11 col-info">
            <h1 class="title-s1">Acerca de nosotros</h1>

            <h5 class="mt-4 subtitle">Misión</h5>
            <p>
              Contribuir a la vitalidad y bienestar, proporcionando productos de agua baja en sodio excelente al gusto.
            </p>

            <h5 class="mt-4 subtitle">Visión institucional</h5>
            <p>
              Somos reconocidos en Tala y en la zona con productos de agua y servicio de la máxima calidad.
            </p>
          </div>
        </div>
      </div>

      <div class="container box-principles">
        <div class="row">
          <div class="col-lg-4 col-principle">
            <div class="box-image">
              <div class="img">
                <img src="public/images/pages/about-us/img-1.png">
              </div>
            </div>

            <h5>RESPONSABILIDAD</h5>
            <p>
              Respondemos con nuestras obligaciones, promesas y objetivos y asumimos las consecuencias de lo que hacemos.
            </p>
          </div>

          <div class="col-lg-4 col-principle">
            <div class="box-image">
              <div class="img">
                <img src="public/images/pages/about-us/img-2.png">
              </div>
            </div>

            <h5>RESPETO</h5>
            <p>
              Actuamos y nos comunicamos con sinceridad y empatía. Nos preocupamos del impacto y afectación de nuestras acciones en los demás.
            </p>
          </div>

          <div class="col-lg-4 col-principle">
            <div class="box-image">
              <div class="img" >
                <img src="public/images/pages/about-us/img-3.png">
              </div>
            </div>

            <h5>CONFIABILIDAD</h5>
            <p>
              Cumplimos con nuestra promesa de valor y hacemos lo necesario para que nuestros clientes reciban lo mejor de nosotros.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="container oversized-container gallery-section">
      <div class="row no-gutters">
        <div class="col-6 col-md-4 col-xl-3 col-image" v-for="(img, iInx) in gallery">
          <div class="t-150 placed-backg image" @click="setIndex(iInx)" v-bind:style="{ backgroundImage: 'url('+img+')' }">
            <img src="public/images/shared/empty.png">
          </div>
        </div>
      </div>
    </section>

    <CoolLightBox
      :items="gallery"
      :index="index"
      :effect="'fade'"
      loop
      @close="index = null">
    </CoolLightBox>

  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
export default {
  components: {
    CoolLightBox,
  },

  data() {
    return {
      index: null,
      gallery: [
        // 'public/images/pages/about-us/image-1.jpg',
        'public/images/pages/about-us/image-10.jpg',
        // 'public/images/pages/about-us/image-5.jpg',
        'public/images/pages/about-us/image-9.jpg',
        // 'public/images/pages/about-us/image-3.jpg',
        'public/images/pages/about-us/image-11.jpg',
        // 'public/images/pages/about-us/image-4.jpg',
        'public/images/pages/about-us/image-12.jpg',
      ],
    }
  },

  methods: {
    setIndex(index) {
      this.index = index
    }
  },
}
</script>
