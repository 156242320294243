<template lang="html">
  <div id="products-results-page">

    <section class="container oversized-container products-section">
      <div class="row">
        <div class="col-12 col-title">
          <h1 class="title">Productos</h1>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-3 col-product-sample-1" v-for="(p, pInx) in products" :key="'pInx-'+pInx">
          <router-link class="t-150 box-product" :to="'/producto/'+p.id">
            <div class="placed-backg image" v-bind:style="{ backgroundImage: 'url('+p.imgUrl+')' }">
              <img src="public/images/shared/empty.png">
            </div>

            <div class="description">
              <h6 class="name">{{ p.name }}</h6>

              <div class="numbers">
                <div class="price">
                  <h6 class="discount" v-if="p.oldprice">${{ p.oldprice }}</h6>
                  <h6 v-if="p.price">Desde ${{ p.price }}</h6>
                </div>

                <h6 class="lts">{{ p.lt }}</h6>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [
        { id: 1, imgUrl: 'public/images/pages/products/product-1-0.jpg', name: 'Garrafón Pet 20 litros',  oldprice: null,   price: '34.00', lt: '20 litros' },
        { id: 2, imgUrl: 'public/images/pages/products/product-2-0.jpg', name: 'Garrafón PVC 11 litros',  oldprice: null,   price: '25.00', lt: '11 litros' },
        { id: 3, imgUrl: 'public/images/pages/products/product-3-0.jpg', name: 'Bolsa con 5 kg de hielo purificado', oldprice: null, price: '18.00', lt: '5 kg' },
        { id: 4, imgUrl: 'public/images/pages/products/product-4-0.jpg', name: 'Botellas 1.5 litros', oldprice: null, price: null, lt: '1.5 litros' },
        { id: 5, imgUrl: 'public/images/pages/products/product-5-0.jpg', name: 'Botellas 1 litro',  oldprice: null,   price: null, lt: '1 litro' },
        { id: 6, imgUrl: 'public/images/pages/products/product-6-0.jpg', name: 'Botellas 500mL',   oldprice: null, price: null, lt: '500mL' },
        { id: 7, imgUrl: 'public/images/pages/products/product-7-0.jpg', name: 'Botellas 250mL',   oldprice: null, price: null, lt: '250mL' },
      ],
    }
  }
}
</script>
