<template lang="html">
  <footer id="footer">
		<div class="container oversized-container">
			<div class="row">
				<div class="col-lg col-logo">
					<router-link to="/">
						<img src="public/images/logo.svg" />
					</router-link>
				</div>

				<div class="col-lg col-content">
					<div class="row">
						<div class="col-md-4 col-lg col-footer col-info">
							<p>
								Checa y compañia S.A. de C.V. Rafaél Ochoa Montaño 98, Tala, Centro C.P. 45300. Tala, Jalisco.
							</p>
						</div>
						<div class="col-md-4 col-lg-2 col-footer col-info">
							<p>
								Teléfono de atención a cliente:<br />
								384 738 3622
							</p>
						</div>
						<div class="col-md-4 col-lg-2 col-footer col-info">
							<p class="mb-2">
								<router-link class="slim-link" to="/contacto">Contacto</router-link>
							</p>
							<p class="mb-2">
								<router-link class="slim-link" to="/aviso-de-privacidad">Aviso de privacidad</router-link>
							</p>
							<!-- <p>
								<router-link class="slim-link" to="/terminos-y-condiciones">Términos y condiciones</router-link>
							</p> -->
						</div>
						<div class="col-lg-2 col-footer col-networks">
							<p>
								<a class="t-150 btn-network" href="#"><i class="fab fa-instagram"></i></a>
								<a class="t-150 btn-network" href="#"><i class="fab fa-facebook-f"></i></a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
}
</script>
