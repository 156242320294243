var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"home-page"}},[_c('section',{staticClass:"banner-section"},[_c('swiper',{staticClass:"swiper desktop",attrs:{"options":_vm.bannersOptions}},[_vm._l((_vm.banners),function(b,bhInx){return _c('swiper-slide',{key:'bhInx-'+bhInx},[_c('div',{staticClass:"placed-backg box",style:({ backgroundImage: 'url('+b.imageUrl+')' })},[_c('img',{attrs:{"src":"public/images/pages/home/banner.png"}})])])}),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2),_vm._v(" "),_c('swiper',{staticClass:"swiper mobile",attrs:{"options":_vm.bannersOptions}},[_vm._l((_vm.banners),function(b,bhmInx){return _c('swiper-slide',{key:'bhmInx-'+bhmInx},[_c('div',{staticClass:"placed-backg box",style:({ backgroundImage: 'url('+b.imageMobUrl+')' })},[_c('img',{attrs:{"src":"public/images/pages/home/banner-m.png"}})])])}),_vm._v(" "),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_vm._v(" "),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1),_vm._v(" "),_c('section',{staticClass:"benefits-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 col-products"},[_c('div',{staticClass:"box"},[_vm._m(0),_vm._v(" "),_c('h4',[_vm._v("Elige tu presentación favorita")]),_vm._v(" "),_c('p',[_c('router-link',{staticClass:"btn btn-s1 btn-lg btn-lightblue",attrs:{"to":"/productos"}},[_vm._v("Realiza tu Pedido")])],1)])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3)])])]),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{attrs:{"src":"public/images/pages/home/products.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-title"},[_c('h2',{staticClass:"title-s1"},[_vm._v("Beneficios de ser clientes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-11 col-md-6 col-benefit"},[_c('div',{staticClass:"col col-icon"},[_c('i',{staticClass:"fal fa-home"})]),_vm._v(" "),_c('div',{staticClass:"col col-text"},[_c('h5',[_vm._v("Agua a tu domicilio.")]),_vm._v(" "),_c('p',[_vm._v("Visitas programadas y pedidos urgentes.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-11 col-md-6 col-benefit"},[_c('div',{staticClass:"col col-icon"},[_c('i',{staticClass:"fal fa-check-circle"})]),_vm._v(" "),_c('div',{staticClass:"col col-text"},[_c('h5',[_vm._v("Servicio de calidad.")]),_vm._v(" "),_c('p',[_vm._v("Confianza, Trato humano, Honestidad.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"trust-section"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("\"Confía."),_c('br'),_vm._v("La confianza en ti mismo es la clave del éxito.\"")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"placed-backg about-us-section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"box"},[_c('h2',{staticClass:"title-s1"},[_vm._v("Agua Saludable, Gente Saludable")]),_vm._v(" "),_c('p',{staticClass:"mb-2 mb-sm-3"},[_vm._v("\n          Checa y compañia es una empresa purificadora de Agua con más de 16 años de experiencia en la ciudad de Tala, Jalisco.\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-2 mb-sm-3"},[_vm._v("\n          Nuestra empresa siempre se ha caracterizado por la calidad de su producto, la cercanía con el cliente y la vanguardia en procesos de lograr agua segura, libre de contaminantes, baja en sales, y purificada que tu familia puedan consumir con plena confianza.\n        ")]),_vm._v(" "),_c('p',{staticClass:"mb-2 mb-sm-3"},[_vm._v("\n          Checa y compañia es una empresa ética, con valores morales que se reflejan en su equipo de trabajo, la confianza de su gente y la preferencia del consumidor y proveedores.\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          Permítenos llegar a tu hogar y cuidarte.\n        ")])])])])
}]

export { render, staticRenderFns }