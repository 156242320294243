<template lang="html">
  <div id="home-page">

    <section class="banner-section">
      <swiper class="swiper desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners" :key="'bhInx-'+bhInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners" :key="'bhmInx-'+bhmInx">
          <div class="placed-backg box" v-bind:style="{ backgroundImage: 'url('+b.imageMobUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </div>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="benefits-section">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-products">
            <div class="box">
              <div class="image">
                <img src="public/images/pages/home/products.jpg">
              </div>

              <h4>Elige tu presentación favorita</h4>

              <p>
                <router-link class="btn btn-s1 btn-lg btn-lightblue" to="/productos">Realiza tu Pedido</router-link>
              </p>
            </div>
          </div>

          <div class="col-12 col-title">
            <h2 class="title-s1">Beneficios de ser clientes</h2>
          </div>

          <div class="col-sm-11 col-md-6 col-benefit">
            <div class="col col-icon">
              <i class="fal fa-home"></i>
            </div>

            <div class="col col-text">
              <h5>Agua a tu domicilio.</h5>

              <p>Visitas programadas y pedidos urgentes.</p>
            </div>
          </div>

          <div class="col-sm-11 col-md-6 col-benefit">
            <div class="col col-icon">
              <i class="fal fa-check-circle"></i>
            </div>

            <div class="col col-text">
              <h5>Servicio de calidad.</h5>

              <p>Confianza, Trato humano, Honestidad.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="trust-section">
      <div class="container">
        <h2>"Confía.<br />La confianza en ti mismo es la clave del éxito."</h2>
      </div>
    </section>

    <section class="placed-backg about-us-section">
      <div class="container">
        <div class="box">
          <h2 class="title-s1">Agua Saludable, Gente Saludable</h2>

          <p class="mb-2 mb-sm-3">
            Checa y compañia es una empresa purificadora de Agua con más de 16 años de experiencia en la ciudad de Tala, Jalisco.
          </p>
          <p class="mb-2 mb-sm-3">
            Nuestra empresa siempre se ha caracterizado por la calidad de su producto, la cercanía con el cliente y la vanguardia en procesos de lograr agua segura, libre de contaminantes, baja en sales, y purificada que tu familia puedan consumir con plena confianza.
          </p>
          <p class="mb-2 mb-sm-3">
            Checa y compañia es una empresa ética, con valores morales que se reflejan en su equipo de trabajo, la confianza de su gente y la preferencia del consumidor y proveedores.
          </p>
          <p>
            Permítenos llegar a tu hogar y cuidarte.
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [
        { imageUrl: 'public/images/pages/home/banner-1.jpg', imageMobUrl: 'public/images/pages/home/banner-1-m.jpg' },
      ],

      // == Carousel options ==
      bannersOptions: {
        loop: true,
        speed: 700,
        effect: 'fade',

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },
      },
      // == ==
    }
  }
}
</script>
